//Fonts
$popins: "Poppins",
sans-serif;

//Colors
$black: #000000;
$white: #ffffff;
$gray1: #c8d6e5;
$gray2: #8395a7;
$gray3: #576574;
$gray4: #222f3e;

$blue: #00d2d3;
$blueh: #01a3a4;
$red: #f85959;
$redh: #d63434;

//Mixins
@mixin prefix($property, $value) {
  //$vendors: 'webkit moz ms o';
  $vendors: webkit moz ms o;
  $default: true;

  // -moz-$property: $value;
  // -webkit-$property: $value;
  // -o-$property: $value;
  // -ms-$property: $value;
  // property: $value
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + $property}: #{$value};
    }
  }

  @if $default {
    #{$property}: #{$value};
  }
}

@mixin transition($time) {
  @include prefix(transition, all $time);
}

@mixin shadow($x, $y, $spread, $color, $opacity) {
  @include prefix(box-shadow, $x $y $spread 0px rgba($color, $opacity));
}

@mixin bradius($size) {
  @include prefix(border-radius, $size + px);
}

@mixin font-size($size) {
  $base: 16;
  font-size: $size + px; // fallback for old browsers
  font-size: ($size / $base) * 1rem;
}

@mixin gradient($angle, $color1, $color2) {
  @include prefix(background, linear-gradient($angle, $color1, $color2) fixed);
}

* {
  box-sizing: border-box;
  color: $gray4;

  input:focus,
  textarea:focus,
  select:focus,
  *:focus {
    outline: none;
  }
}

body {
  margin: 0px;
  padding: 0px;
  font-family: $popins, Arial, Helvetica, sans-serif !important;
}

#screenRes {
  font-family: Arial, Helvetica, sans-serif;
  @include font-size(14);
  color: red;
  position: fixed;
  z-index: 50;
  //display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0px;
  @include font-size(16);
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  li {
    display: inline-block;
  }
}

.container {
  width: 96%;
  max-width: 1200px;
  margin: 0px auto;
  position: relative;
}

.container-f {
  width: 100%;
  margin: 0px auto;
  position: relative;
}

.col {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparentize($black, 0.2);
  z-index: 20;
  opacity: 0;
  pointer-events: none;
  @include transition(0.3s);

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}

.msgPop {
  position: fixed;
  top: -100%;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%);
  width: 90%;
  max-width: 700px;
  min-height: 200px;
  background: $white;
  text-align: center;
  z-index: 11;

  #cerrarPopup {
    position: absolute;
    display: block;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
    //background: $lgray;
    padding: 10px;
    cursor: pointer;
    @include transition(0.2s);

    svg {
      @include transition(0.2s);
      @include prefix(transform, scale(0.9));

      g {
        path {
          @include transition(0.2s);
          fill: $gray1;
        }
      }
    }

    &:hover {

      //background: $dgray;
      svg {
        @include prefix(transform, scale(0.7));

        g {
          path {
            fill: $gray2;
          }
        }
      }
    }
  }

  p {
    width: 50%;
    @include font-size(22);
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 5px;
  background: $blue;
  @include transition(0.2s);
  z-index: 30;
}

#loadingNotas {
  @mixin highlight() {
    background-image: linear-gradient(90deg,
        lighten($gray1, 12) 0px,
        $gray1 90px,
        lighten($gray1, 12) 100px);
    background-size: 600px;
  }

  @keyframes highlightAvatar {
    0% {
      background-position: -150px + 60px;
    }

    100% {
      background-position: 300px + 60px;
    }
  }

  @keyframes highlightLines {
    0% {
      background-position: -150px;
    }

    100% {
      background-position: 300px;
    }
  }

  .post {
    width: 300px;
    margin: 0 auto;
    margin-bottom: 20px;

    .avatar {
      width: 20%;
      height: 60px;
      background: lighten($gray1, 12);
      @include bradius(10);
      margin-right: 5%;
      @include highlight();
      animation: highlightAvatar 2.3s infinite ease-out;
    }

    .lineas {
      width: 75%;

      .linea {
        width: 100%;
        height: 20px;
        background: lighten($gray1, 12);
        margin: 10px 0px;
        @include bradius(5);
        @include highlight();
        animation: highlightLines 2.3s infinite ease-out;

        &:first-child {
          width: 70%;
        }
      }
    }
  }
}

#titulosAlerta {
  position: fixed;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding: 5px 10px;
  background: $redh;
  @include bradius(5);
  text-transform: uppercase;
  color: $white;
  @include font-size(10);
  letter-spacing: 0.77px;
  pointer-events: none !important;
  display: none;
  opacity: 0.5;
  z-index: 10;
}

#realizarBusqueda {
  position: fixed;
  bottom: 10px;
  right: 0px;
  width: 100%;
  max-width: 600px;
  z-index: 27;
  display: block;

  #realizarBusquedaResultados {
    width: 100%;
    height: 400px;
    @include prefix(overflow-y, scroll);
    background: $white;
    margin-bottom: 20px;
    @include bradius(10);
    padding: 20px;

    hr {
      border: .5px solid $gray1;
    }
  }

  form {
    padding: 20px;
    background: $white;
    @include bradius(10);
    position: relative;

    #cerrarBuscador {
      background: none;
      border: none;
      position: absolute;
      display: block;
      top: 0px;
      right: 0px;
      width: 50px;
      height: 50px;
      //background: $lgray;
      padding: 10px;
      cursor: pointer;
      @include transition(0.2s);

      svg {
        @include transition(0.2s);
        @include prefix(transform, scale(0.9));

        g {
          path {
            @include transition(0.2s);
            fill: $gray1;
          }
        }
      }

      &:hover {

        //background: $dgray;
        svg {
          @include prefix(transform, scale(0.7));

          g {
            path {
              fill: $gray2;
            }
          }
        }
      }
    }

    input {
      width: 100%;
      margin-top: 10px;
      padding: 10px 20px;
      @include font-size(16);
      line-height: 18px;
      color: $gray1;
      border: 1px solid $gray1;
      @include bradius(10);
      @include transition(0.2s);

      &:hover {
        color: $gray2;
      }

      &:focus {
        color: $gray4;
      }
    }
  }
}

#estamosEnVivo {
  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 12;

  iframe {
    width: 450px;
    height: 267px;
    border-radius: 10px 10px 0px 0px;
  }

  #cerrarVivo {
    position: absolute;
    display: block;
    bottom: -22px;
    right: 0px;
    width: 100%;
    height: 30px;
    background: $gray4;
    padding: 10px;
    border: none;
    cursor: pointer;
    @include transition(0.2s);
    border-radius: 0px 0px 10px 10px;

    svg {
      position: absolute;
      width: 20px;
      @include transition(0.2s);
      @include prefix(transform, translate(-44%, -44%) scale(0.8));

      g {
        path {
          @include transition(0.2s);
          fill: $white;
        }
      }
    }

    &:hover {
      background: $blueh;

      //background: $dgray;
      svg {
        g {
          path {
            fill: $gray4;
          }
        }
      }
    }
  }
}

#mmbtn {
  position: fixed;
  top: 5px;
  right: 0px;
  z-index: 25;
  //border: 1px solid red;
  padding: 45px;
  cursor: pointer;
  display: none;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%));
    @include prefix(transform-origin, center center);
    width: 30px;
    height: 3px;
    @include bradius(5);
    background: $blueh;
    display: block;
    @include transition(0.3s);

    &:nth-child(1) {
      width: 40px;
      top: 36%;
    }

    &:nth-child(3) {
      width: 40px;
      top: 65%;
    }
  }

  &.open {
    span {
      background: $blue;

      &:nth-child(2) {
        left: 200%;
        opacity: 0;
      }

      &:nth-child(1) {
        top: 50%;
        @include prefix(transform, translateX(-50%) rotate(45deg));
      }

      &:nth-child(3) {
        top: 50%;
        @include prefix(transform, translateX(-50%) rotate(-45deg));
      }
    }
  }
}

#menuMobile {
  display: none;
  position: fixed;
  top: 0px;
  right: -100%;
  width: 80%;
  height: 100%;
  border-left: 1px solid $gray1;
  background: $white;
  z-index: 22;
  @include transition(0.2s);

  nav {
    position: absolute;
    top: 50%;
    right: 30px;
    @include prefix(transform, translate(0%, -50%));

    ul {
      li {
        width: 100%;
        text-align: right;
        margin: 8px 0px;
        @include transition(0.2s);
        //border: 1px solid red;

        a {
          @include font-size(18);
          color: $gray2;
          padding: 5px;
          @include transition(0.2s);

          &:hover {
            color: $redh;
          }

          &.menuActive {
            color: $redh;
            font-weight: 700;
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          a {
            color: $redh;
            font-weight: 700;
          }
        }
      }
    }
  }

  .navSocial {
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 50%;
    @include prefix(transform, translateX(-50%));
    text-align: center;

    ul {
      li {
        margin-right: 15px;

        &:last-child {
          margin-right: 0px;
          display: none;
        }

        a {
          display: block;
          width: 25px;
          height: 25px;

          svg {
            .cls-1 {
              fill: $white;
            }

            .cls-2 {
              @include transition(0.2s);
              fill: $gray1;
            }
          }

          &:hover {
            svg {
              .cls-2 {
                fill: $redh;
              }
            }
          }
        }

        button {
          display: block;
          width: 20px;
          height: 20px;
          border: none;
          background: none;
          padding: 0;
          cursor: pointer;

          svg {
            .cls-1 {
              fill: $white;
            }

            .cls-2 {
              @include transition(0.2s);
              fill: $gray1;
            }
          }

          &:hover {
            svg {
              .cls-2 {
                fill: $redh;
              }
            }
          }
        }
      }
    }
  }

  &.open {
    right: 0%;
  }
}

.cc {
  cursor: not-allowed;
}

.seccionTitulos {
  font-weight: 700;
  @include font-size(24);
  line-height: 34px;
  color: $gray1;
  margin-bottom: 30px;

  a {
    font-weight: 400;
    font-style: italic;
    @include font-size(14);
    line-height: 34px;
    text-align: left;
    color: $blueh;
    margin-left: 20px;
    @include transition(0.2s);

    svg {
      width: 15px;
      opacity: 0;
      margin-left: -20px;
      @include transition(0.2s);

      .cls-1 {
        fill: $blueh;
        stroke: $blueh;
        stroke-linecap: round;
        @include transition(0.1s);
      }
    }

    &:hover {
      color: $blue;

      svg {
        opacity: 1;
        margin-left: 5px;

        .cls-1 {
          fill: $blue;
          stroke: $blue;
        }
      }
    }
  }

  span {
    display: block;
    width: 27px;
    height: 1px;
    background: $gray1;
    margin-top: 10px;
    @include transition(0.2s);
  }

  &:hover {
    span {
      width: 40px;
    }
  }
}

.notasCategorias {
  font-weight: 700;
  @include font-size(14);
  letter-spacing: 0.07em;
  line-height: 34px;
  color: $blueh;
  text-transform: uppercase;
  margin-bottom: 0px;
  display: block;
}

.notasTitulos {
  font-weight: 700;
  @include font-size(28);
  line-height: 34px;
  color: $gray3;
  margin-bottom: 10px;
  display: block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.notasFechas {
  @include font-size(14);
  line-height: 34px;
  text-align: left;
  color: $gray2;
  font-style: italic;
  margin-bottom: 20px;
  display: block;
  //text-transform: capitalize;

  .notaAutor {
    font-weight: 700;
    color: $blueh;
  }
}

.notasCuerpo {
  @include font-size(16);
  line-height: 26px;
  color: $gray3;
  margin-bottom: 30px;
  display: block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &.notasCuerpoCompleto {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.leerMas {
  text-transform: uppercase;
  font-weight: 700;
  @include font-size(14);
  letter-spacing: 0.07em;
  line-height: 34px;
  color: $blueh;
  @include transition(0.1s);
  cursor: pointer;

  svg {
    width: 15px;
    @include transition(0.2s);

    .cls-1 {
      fill: $blueh;
      stroke: $blueh;
      stroke-linecap: round;
      @include transition(0.1s);
    }
  }

  &:hover {
    color: $blue;

    svg {
      margin-left: 8px;

      .cls-1 {
        fill: $blue;
        stroke: $blue;
      }
    }
  }
}

.notasCard {
  vertical-align: top;
  position: relative;

  .notasCategorias,
  .notasCuerpo,
  .leerMas {
    @include font-size(14);
  }

  .notasTitulos {
    @include font-size(22);
  }

  //width: calc(100% / 4 - 1.8%);
  //margin: 1%;
  border: 1px solid $white;
  @include bradius(10);
  @include transition(0.2s);

  .notasImagenContainer {
    width: 100%;
    height: 150px;
    background: $gray1;
    @include bradius(10);
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      @include prefix(transform, translate(-50%, -50%));
      width: 110%;
      @include transition(0.2s);

      &:hover {
        width: 130%;
      }
    }
  }

  .notasFechas,
  .notasCategorias,
  .notasTitulos,
  .notasCuerpo,
  .leerMas {
    padding: 0px 15px;
  }

  .leerMas {
    display: block;
    padding-bottom: 20px;
  }

  // Selecciona todos los items de la izquierda
  // &:nth-child(4n+2) {
  //     margin-left: 0%;
  // }

  // Selecciona todos los items de la derecha
  // &:nth-child(4n+5) {
  //     margin-right: 0%;
  // }

  &:hover {
    border: 1px solid $gray1;
    @include shadow(0px, 10px, 20px, $black, 0.05);
  }
}

.navSocial {
  ul {
    li {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }

      a {
        display: block;
        width: 20px;
        height: 20px;

        svg {
          .cls-1 {
            fill: $white;
          }

          .cls-2 {
            @include transition(0.2s);
            fill: $gray1;
          }
        }

        &:hover {
          svg {
            .cls-2 {
              fill: $redh;
            }
          }
        }
      }

      button {
        display: block;
        width: 20px;
        height: 20px;
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;

        svg {
          .cls-1 {
            fill: $white;
          }

          .cls-2 {
            @include transition(0.2s);
            fill: $gray1;
          }
        }

        &:hover {
          svg {
            .cls-2 {
              fill: $redh;
            }
          }
        }
      }
    }
  }
}

header {
  position: fixed;
  top: 5px;
  left: 0;
  width: 100%;
  padding: 20px 0px;
  z-index: 25;
  background: $white;
  border-bottom: 1px solid $gray1;

  .navLogo {
    width: 200px;
    margin-right: 30px;
    position: relative;
    z-index: 22;
  }

  #menuDesktop {
    width: calc(100% - 238px - 160px);

    ul {
      li {
        margin-right: 10px;
        @include transition(0.2s);

        a {
          @include font-size(16);
          color: $gray2;
          padding: 5px;
          @include transition(0.2s);

          &:hover {
            color: $redh;
          }

          &.menuActive {
            color: $redh;
            font-weight: 700;
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          a {
            color: $redh;
            font-weight: 700;
          }
        }
      }
    }
  }

  .navSocial {
    width: 160px;
    text-align: right;

    ul {
      li {
        margin-right: 10px;

        &:last-child {
          margin-right: 0px;
        }

        a {
          display: block;
          width: 20px;
          height: 20px;

          svg {
            .cls-1 {
              fill: $white;
            }

            .cls-2 {
              @include transition(0.2s);
              fill: $gray1;
            }
          }

          &:hover {
            svg {
              .cls-2 {
                fill: $redh;
              }
            }
          }
        }
      }
    }
  }
}

main {
  margin-top: 80px;
}

section {
  padding: 50px 0px;
}

.article {
  width: 70%;
  margin: 0px 3%;
  display: inline-block;
  border: 1px solid lighten($gray1, 12);
  @include bradius(10);

  .articleInfo {
    width: 100%;
    @include gradient(45deg, darken($blueh, 15), $blue);
    @include bradius(10);
    padding: 120px 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
    transform: scale(1.1);

    .notasTitulos {
      @include font-size(42);
      line-height: 40px;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 0px;
      position: relative;
    }

    .info,
    .category {
      position: absolute;
      bottom: 20px;
      display: inline-block;

      p,
      time {
        margin: 0;
        font-style: italic;
        @include font-size(14);
        color: $white;

        strong {
          color: $white;
        }
      }
    }

    .info {
      bottom: 30px;
    }

    .category {
      right: 20px;
      padding: 20px;
      margin-bottom: 10px;
      background: darken($blue, 20);
      @include bradius(10);

      p {
        line-height: 0;
        text-transform: uppercase;
        letter-spacing: .69px;
      }
    }


    #articleCoverImage {
      position: absolute;
      top: 50%;
      left: 50%;
      @include prefix(transform, translate(-50%, -50%));
      width: 110%;
      @include transition(0.2s);
      opacity: .1;
    }
  }

  h2 {
    @include font-size(22);
    color: $blueh;
    margin-bottom: 20px;
    font-weight: 400;
  }

  h4 {
    @include font-size(18);
    color: $blueh;
    margin-bottom: 20px;
  }

  p {
    @include font-size(16);
    color: $gray4;
    margin-bottom: 20px;

    strong {
      color: $redh;
    }

    ul {
      list-style-type: disc !important;
      color: $redh !important;

      li {
        display: block !important;
        width: 100% !important;
        color: blue !important;
      }
    }
  }

  img {
    display: block;
    margin: 0 auto;
    border: 0;
    width: 80%;
    margin-bottom: 30px;
    @include bradius(10);
  }
}

#blogNotas,
#blogNotasMarketing,
#blogNotasVentas {
  width: 75%;
  margin-right: 1.6%;

  .notasCard {
    width: calc(100% / 2 - 2.4%);
    margin: 2%;
    vertical-align: top;

    .notasImagenContainer {
      height: 200px;

      img {
        font-size: 0px;
      }
    }

    // Selecciona todos los items de la izquierda
    &:nth-child(2n + 1) {
      margin-left: 0%;
      //background: red;
    }

    // Selecciona todos los items de la derecha
    &:nth-child(2n + 1) {
      margin-right: 0%;
    }

    &:hover {
      border: 1px solid $gray1;
    }
  }
}

#rquill {
  .ql-toolbar {
    border: 1px solid $gray1;
    @include bradius(10);
  }

  .ql-container {
    height: 400px;
    border: 1px solid $gray1;
    border-top: none;
    overflow-y: scroll;
  }
}

.sidebar {
  width: 23%;
  max-width: 300px;
  vertical-align: top;
  position: relative;
  padding: 20px;

  #sBuscador,
  #sCategorias,
  #sYoutube {
    margin-bottom: 50px;

    .notasCategorias {
      color: $gray4;
      position: relative;
    }
  }

  #sBuscador {
    form {
      input {
        width: 100%;
        padding: 15px;
        @include font-size(16);
        color: $gray2;
        border: 1px solid $gray1;
        @include bradius(8);
        @include transition(0.2s);
        margin-bottom: 10px;

        &:focus {
          border: 1px solid $blue;
          color: $gray4;
        }
      }

      .cta {
        display: block;
        width: 100%;
        padding: 15px;
        background: $blueh;
        text-align: center;
        @include font-size(16);
        color: $white;
        border: none;
        @include bradius(8);
        @include transition(0.2s);

        &:hover {
          background: $blue;
          @include shadow(0px, 10px, 20px, $blueh, 0.1);
        }
      }
    }
  }

  #sCategorias {
    .sidebarCategoria {
      display: block;
      width: 100%;
      height: 90px;
      background: $black;
      margin-bottom: 20px;
      overflow: hidden;
      position: relative;
      @include bradius(10);

      span {
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        @include prefix(transform, translate(-50%, -50%));
        font-weight: 700;
        color: $white;
        @include font-size(20);
        @include transition(0.2s);
      }

      img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        @include prefix(transform, translate(-50%, -50%));
        z-index: 1;
        opacity: 0.5;
        @include transition(0.2s);
      }

      &:hover {
        span {
          text-shadow: 1px 1px 5px $black;
          left: 40%;
        }

        img {
          opacity: 0.9;
        }
      }
    }
  }

  #sYoutube {
    .notasCategorias {
      svg {
        width: 20px;
        position: relative;
        top: 5px;

        .cls-1 {
          fill: $white;
        }

        .cls-2 {
          fill: red;
        }
      }
    }

    .videoCard {
      width: 100%;
      height: 120px;
      @include bradius(10);
      background: $gray3;
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      @include transition(0.2s);

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        @include prefix(transform, translate(-50%, -50%));
        width: 110%;
        @include transition(0.2s);
        opacity: 0.5;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        @include prefix(transform, translate(-50%, -50%));
        z-index: 5;
        width: 100px;

        .cls-1 {
          fill: $white;
        }

        .cls-2 {
          fill: red;
        }
      }

      &:hover {
        background: $gray4;

        img {
          width: 130%;
        }
      }
    }

    .cta {
      display: block;
      width: 100%;
      padding: 10px;
      background: $redh;
      text-align: center;
      color: $white;
      @include bradius(10);
      @include transition(0.2s);

      &:hover {
        background: $red;
        @include shadow(0px, 10px, 20px, $redh, 0.1);
      }
    }
  }
}

#blog {
  .notasCard {
    .notasFechas {
      position: absolute;
      bottom: 1px;
      right: 15px;
    }
  }
}

#notasRecientes {
  .notaPrincipal {
    width: 67%;
    margin-right: 3%;

    .imagenContainer {
      width: 100%;
      height: 500px;
      background: $gray1;
      @include bradius(10);
      @include shadow(0px, 10px, 20px, $black, 0.1);
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        @include prefix(transform, translate(-50%, -50%));
        width: 110%;
        @include transition(0.2s);

        &:hover {
          width: 130%;
        }
      }
    }
  }

  .videoPrincipal {
    width: 67%;
    margin-right: 3%;

    .videoContainer {
      width: 100%;
      height: 500px;
      background: $gray1;
      @include bradius(10);
      @include shadow(0px, 10px, 20px, $black, 0.1);
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;

      iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        @include prefix(transform, translate(-50%, -50%));
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  .masNotas {
    width: 30%;
    vertical-align: top;
  }
}

#notasDestacadas {
  .nota {
    vertical-align: top;
    width: calc(100% / 2 - 2%);
    height: 100%;
    padding: 20px;
    border: 1px solid $gray1;
    @include bradius(10);
    margin: 1%;
    @include transition(0.4s);



    &:hover {
      @include shadow(0px, 20px, 20px, $black, 0.1);
    }
  }
}

#newsletter {
  .suscribirse {
    width: 100%;
    padding: 50px 40px;
    background: url(../images/newsletter-bg.png) center center no-repeat, $gray1;
    background-size: cover;
    @include bradius(10);
    @include shadow(0px, 20px, 80px, $blue, 0.1);

    .notasTitulos {
      color: $white;
      width: 50%;
      display: inline-block;
      margin-right: 8%;
    }

    form {
      width: 40%;
      display: inline-block;
      position: relative;

      input {
        position: relative;
        width: 100%;
        padding: 20px 20px;
        padding-right: 160px;
        @include font-size(16);
        line-height: 18px;
        color: $gray1;
        border: none;
        @include bradius(10);
        @include transition(0.2s);

        &:hover {
          color: $gray2;
        }

        &:focus {
          color: $gray4;
        }
      }

      button {
        position: absolute;
        top: 50%;
        right: 5px;
        @include prefix(transform, translateY(-50%));
        width: 135px;
        padding: 15px 20px;
        text-transform: uppercase;
        font-weight: 700;
        @include font-size(14);
        letter-spacing: 0.02em;
        line-height: 18px;
        color: $white;
        border: none;
        @include bradius(10);
        background: $blueh;
        cursor: pointer;
        @include transition(0.2s);

        &:hover {
          background: $blue;
        }
      }
    }
  }
}

#ultimasNotas {
  .notasCard {
    width: calc(100% / 4 - 1.8%);
    margin: 1%;

    // Selecciona todos los items de la izquierda
    &:nth-child(4n + 2) {
      margin-left: 0%;
    }

    // Selecciona todos los items de la derecha
    &:nth-child(4n + 5) {
      margin-right: 0%;
    }
  }
}

#ultimosVideos {
  .videoCard {
    width: calc(100% / 3 - 1.8%);
    height: 200px;
    margin: 1%;
    @include bradius(10);
    background: $gray3;
    position: relative;
    overflow: hidden;
    @include transition(0.2s);

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      @include prefix(transform, translate(-50%, -50%));
      width: 110%;
      @include transition(0.2s);
      opacity: 0.5;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      @include prefix(transform, translate(-50%, -50%));
      z-index: 5;
      width: 100px;

      .cls-1 {
        fill: $white;
      }

      .cls-2 {
        fill: red;
      }
    }

    &:hover {
      background: $gray4;

      img {
        width: 130%;
      }
    }

    // Selecciona todos los items de la izquierda

    &:nth-child(3n + 1) {
      margin-left: 0%;
    }

    // Selecciona todos los items de la derecha
    &:nth-child(3n + 3) {
      margin-right: 0%;
    }
  }
}

#ultimasNotas--categorias {
  .categorias {
    width: calc(100% / 2 - 1%);

    &:nth-child(1) {
      //background: red;
      margin-right: 1%;
    }

    .notasCard {
      width: calc(100% / 2 - 4%);
      margin: 2%;

      // Selecciona todos los items de la izquierda
      &:nth-child(2n + 2) {
        margin-left: 0%;
      }

      // Selecciona todos los items de la derecha
      &:nth-child(2n + 3) {
        margin-right: 0%;
      }
    }
  }
}

#otrasEmpresas {
  .ecomedios {
    width: 100%;
    padding: 80px 30px;
    @include bradius(10);
    background: url(../images/bg/patter-ecomedios.svg) top left repeat,
      url(../images/bg/ecomedios-bg.svg) center center no-repeat;
    margin-bottom: 20px;

    .logo {
      width: 15%;
      margin-right: 30px;

      img {
        width: 100%;
      }
    }

    .notasTitulos {
      width: 45%;
      display: inline-block;
      margin-right: 40px;
      @include font-size(28);
      line-height: 30px;
      text-align: left;
      color: #fff;
      text-transform: uppercase;
    }

    .botonEcomedios {
      margin-left: 5%;
      width: 25%;
      padding: 12px 0px;
      text-align: center;
      @include bradius(30);
      background: $white;
      font-weight: 700;
      @include font-size(18);
      line-height: 30px;
      color: $blueh;
      text-transform: uppercase;
      @include transition(0.2s);

      &:hover {
        color: $blue;
        @include shadow(0px, 10px, 20px, $black, 0.2);
      }
    }
  }

  .empresa {
    width: calc(100% / 3 - 1.6%);
    height: 150px;
    margin: 1%;
    position: relative;
    @include bradius(10);
    @include transition(0.2s);

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      @include prefix(transform, translate(-50%, -50%));
      width: 150px;
      @include transition(0.2s);
      transform-origin: center;
    }

    &:nth-child(3) {
      margin-left: 0px;
      background: url(../images/bg/pattern-delenio.svg) top left repeat,
        url(../images/bg/delenio-bg.svg) center center no-repeat;
      background-size: cover, cover;
    }

    &:nth-child(4) {
      background: url(../images/bg/pattern-tribu.svg) top left repeat,
        url(../images/bg/tribu-bg.svg) center center no-repeat;
      background-size: 81px, cover;
    }

    &:nth-child(5) {
      margin-right: 0px;
      background: url(../images/bg/pattern-selica.svg) top left repeat,
        url(../images/bg/selica-bg.svg) center center no-repeat;
      background-size: 37px, cover;
    }

    &:hover {
      img {
        @include prefix(transform, translate(-50%, -50%) scale(1.2));
      }
    }
  }
}

#contacto {
  .datos {
    width: 52%;
    margin-right: 2%;
    vertical-align: top;

    h1 {
      @include font-size(36);
      color: $blue;
      text-transform: uppercase;
    }

    p {
      @include font-size(16);
      color: $gray3;
      margin-bottom: 20px;
    }
  }

  .contactanos {
    width: 45%;
    vertical-align: top;
    padding: 20px;
    border: 1px solid $gray1;
    @include bradius(10);

    h4 {
      @include font-size(20);
      color: $gray3;

      a {
        color: $blue;
        font-weight: 400;
        @include transition(.2s);

        &:hover {
          color: $blueh;
        }
      }
    }

    form {
      margin-top: 20px;
    }

    .formGroup {
      width: 100%;
      margin-bottom: 20px;

      label {
        display: block;
        @include font-size(16);
        color: $gray2;
      }

      input,
      textarea,
      select {
        width: 100%;
        padding: 15px;
        font-family: $popins;
        @include font-size(16);
        color: $gray2;
        border: 1px solid $gray1;
        @include bradius(8);
        @include transition(0.2s);
        margin-bottom: 10px;

        &:focus {
          border: 1px solid $blue;
          color: $gray4;
        }

        &[type="checkbox"] {
          width: 30px;
        }

        &:invalid:focus {
          border: 1px solid red;
        }
      }

      .cta {
        display: block;
        width: 100%;
        padding: 15px;
        background: $blueh;
        text-align: center;
        @include font-size(16);
        color: $white;
        border: none;
        @include bradius(8);
        @include transition(0.2s);
        cursor: pointer;

        &:hover {
          background: $blue;
          @include shadow(0px, 10px, 20px, $blueh, 0.1);
        }

        &.disabled {
          background: $gray2;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }

  }
}

footer {
  width: 100%;
  border-top: 1px solid $gray1;
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
  position: relative;
  z-index: 11;
  background: $white;

  .logo {
    width: 100px;
    margin: 0 auto;
    margin-bottom: 30px;

    svg {
      width: 100%;
    }
  }

  .navSocial {
    margin-bottom: 20px;
  }

  .copyright {
    p {
      @include font-size(14);
      color: $gray1;
    }
  }
}

#temporalCrearArticulo {
  width: 70%;
  margin: 0 auto;

  .formGroup {
    width: 100%;
    margin-bottom: 20px;

    label {
      display: block;
      @include font-size(16);
      color: $gray2;
    }

    input,
    textarea,
    select {
      width: 100%;
      padding: 15px;
      font-family: $popins;
      @include font-size(16);
      color: $gray2;
      border: 1px solid $gray1;
      @include bradius(8);
      @include transition(0.2s);
      margin-bottom: 10px;

      &:focus {
        border: 1px solid $blue;
        color: $gray4;
      }

      &[type="checkbox"] {
        width: 30px;
      }
    }

    .cta {
      display: block;
      width: 100%;
      padding: 15px;
      background: $blueh;
      text-align: center;
      @include font-size(16);
      color: $white;
      border: none;
      @include bradius(8);
      @include transition(0.2s);

      &:hover {
        background: $blue;
        @include shadow(0px, 10px, 20px, $blueh, 0.1);
      }
    }
  }

  .traerImagen {
    img {
      width: 400px;
    }
  }
}

#temporalListarArticulos {
  .item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid $gray1;
    @include bradius(10);

    .datos {
      width: 80%;
      margin: 1%;

      h2 {
        @include font-size(28);
        color: $gray3;
      }

      p {
        @include font-size(16);
        color: $gray1;
      }
    }

    .acciones {
      width: 17%;

      a,
      button {
        display: inline-block;
        border: none;
        cursor: pointer;
        margin: 10px;
        padding: 10px 20px;
        @include bradius(10);

        &.y {
          background: #f1c40f;

          &:hover {
            background: lighten(#f1c40f, 10);
            color: $white;
          }
        }

        &.r {
          background: #e74c3c;

          &:hover {
            background: lighten(#e74c3c, 10);
            color: $white;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .container {
    width: 90%;
  }

  #realizarBusqueda {
    right: 50%;
    @include prefix(transform, translateX(50%));
  }

  #mmbtn,
  #menuMobile {
    display: block;
  }

  header {

    #menuDesktop,
    .navSocial {
      display: none;
    }
  }

  main {
    margin-top: 80px;
  }

  section {
    padding: 40px 0px;
  }

  #notasRecientes {

    .notaPrincipal,
    .videoPrincipal {
      width: 100%;
      margin-right: 0%;
      margin-bottom: 50px;

      .imagenContainer,
      .videoCard {
        height: 400px;
      }
    }

    .masNotas {
      width: 100%;

      .nota {
        margin: 30px 0px;
        border-bottom: 1px solid $gray1;
      }
    }
  }

  #notasDestacadas {
    .nota {
      width: 48%;
    }
  }

  #newsletter {
    .suscribirse {
      form {
        input {
          padding: 20px;
        }

        button {
          width: 100%;
          position: relative;
          top: 0%;
          right: 0;
          @include prefix(transform, translateY(0%));
          background: $gray3;
        }
      }
    }
  }

  #ultimasNotas {
    .notasCard {
      width: calc(100% / 2 - 1.8%);

      // Selecciona todos los items de la izquierda
      &:nth-child(2n + 2) {
        margin-left: 0%;
      }

      // Selecciona todos los items de la derecha
      &:nth-child(2n + 3) {
        margin-right: 0%;
      }
    }
  }

  #blogNotas,
  #blogNotasMarketing,
  #blogNotasVentas {
    width: 100%;

    .notasCard {
      width: calc(100% / 2 - 1.8%);

      // Selecciona todos los items de la izquierda
      &:nth-child(2n + 1) {
        margin-left: 0%;
        //background: red;
      }

      // Selecciona todos los items de la derecha
      &:nth-child(2n + 2) {
        margin-right: 0%;
      }
    }
  }

  .sidebar {
    display: none;
  }

  #ultimosVideos {
    .videoCard {
      width: calc(100% / 2 - 1.8%);

      &:nth-child(1n + 1) {
        margin-left: 0%;
      }

      // Selecciona todos los items de la derecha
      &:nth-child(1n + 2) {
        margin-right: 0%;
      }
    }
  }

  #ultimasNotas--categorias {
    .categorias {
      width: 100%;

      &:nth-child(1) {
        margin-bottom: 30px;
      }
    }
  }

  #otrasEmpresas {
    .ecomedios {
      padding: 50px 30px;

      .logo {
        width: 25%;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
      }

      .notasTitulos {
        width: 50%;
        margin-right: 20px;

        br {
          display: none;
        }
      }

      .botonEcomedios {
        width: 40%;
      }
    }

    .empresa {
      width: calc(100% / 3 - 2%);

      &:nth-child(3) {
        margin-left: 0px;
        background: url(../images/bg/pattern-delenio.svg) top left repeat,
          url(../images/bg/delenio-bg.svg) center center no-repeat;
        background-size: cover, cover;
      }

      &:nth-child(4) {
        background: url(../images/bg/pattern-tribu.svg) top left repeat,
          url(../images/bg/tribu-bg.svg) center center no-repeat;
        background-size: 81px, cover;
      }

      &:nth-child(5) {
        margin-right: 0px;
        background: url(../images/bg/pattern-selica.svg) top left repeat,
          url(../images/bg/selica-bg.svg) center center no-repeat;
        background-size: 37px, cover;
      }
    }
  }

  #contacto {
    .datos {
      width: 100%;
      margin-right: 0%;
      margin-bottom: 20px;
    }

    .contactanos {
      width: 100%;
      padding: 0px;
      border: none;
    }
  }

  .article {
    width: 100%;
    margin: 0;

    .articleInfo {
      transform: scale(1.1);

      .notasTitulos {
        @include font-size(24);
        line-height: 36px;
      }

      .category {
        top: 20px;
        height: 40px;
      }
    }
  }
}

@media (max-width: 700px) {
  #estamosEnVivo {
    @include prefix(border-top-right-radius, 0px);
    position: fixed;
    bottom: 10%;
    right: 50%;
    @include prefix(transform, translateX(50%));
    @include transition(0.8s);
    z-index: 12;

    iframe {
      width: 350px;
      height: 198px;
    }

    #cerrarVivo {
      bottom: -33px;
      right: 0px;
      width: 100%;
      height: 40px;
      background: $blue;

      svg {
        width: 30px;
        @include prefix(transform, translate(-50%, -50%) scale(0.5));

        g {
          path {
            @include transition(0.2s);
            fill: $gray4
          }
        }
      }
    }
  }

  #realizarBusqueda {
    #realizarBusquedaResultados {
      height: 250px;
    }
  }

  .notasTitulos {
    @include font-size(22);
  }

  #notasRecientes {

    .notaPrincipal,
    .videoPrincipal {

      .imagenContainer,
      .videoContainer {
        height: 250px;

        img {
          width: 120%;
        }
      }
    }
  }

  #notasDestacadas {
    .nota {
      width: 100%;
      margin: 20px 0%;
    }
  }

  #newsletter {
    .suscribirse {
      .notasTitulos {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
      }

      form {
        width: 100%;
      }
    }
  }

  #ultimasNotas {
    .notasCard {
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  #blogNotas,
  #blogNotasMarketing,
  #blogNotasVentas {
    .notasCard {
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  #ultimosVideos {
    .videoCard {
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  #ultimasNotas--categorias {
    .categorias {
      .notasCard {
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  #otrasEmpresas {
    .ecomedios {
      background-size: 72px, cover;

      .logo {
        width: 200px;
      }

      .notasTitulos {
        width: 100%;
        text-align: center;
        @include font-size(22);
        margin-bottom: 30px;
      }

      .botonEcomedios {
        width: 100%;
        margin: 0;
      }
    }

    .empresa {
      width: 100%;
      margin: 0;
      margin-bottom: 20px;
    }
  }
}